import React from "react"
import Header from "./Header"
import Footer from "./organisms/Footer"
import SEO from "./seo"
import { updateAppLoadedValue } from "../redux/actions"

import withReduxStore from "../lib/with-redux-store"
import { Provider } from "react-redux"

class Layout extends React.Component {
  componentDidMount() {
    if (
      this.props.reduxStore &&
      !this.props.reduxStore.getState().mainReducer.appLoaded
    ) {
      this.props.reduxStore.dispatch(updateAppLoadedValue(true))
    }
  }

  render() {
    const { children, reduxStore } = this.props

    return (
      <Provider store={reduxStore}>
        <SEO />
        <Header />
        {children}
        <Footer />
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(u,x,s,n,i,f){
        u.ux=u.ux||function(){(u.ux.q=u.ux.q||[]).push(arguments)};
        u._uxSettings={uxid:'158737259-1',uxv:'U'};
        i=x.getElementsByTagName('head')[0];
        f=x.createElement('script');f.async=1;
        f.src=s+n+u._uxSettings.uxv+'A-'+u._uxSettings.uxid;
        i.appendChild(f);
    })(window,document,'https://s3-ap-southeast-1.amazonaws.com/uxsniff/uxsnf_track','.js?uxid=');`,
          }}
        />
      </Provider>
    )
  }
}

export default withReduxStore(Layout)
