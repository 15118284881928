import React from 'react'
import InputError from '../InputError'
import styles from './input.module.scss'

export default ({ input, label, type, meta: { touched, error } }) =>
  <div className={`${styles.input} ${touched && error ? styles.invalid : ''}  ${touched && !error && input.value ? styles.valid : ''} input`}>
    <div>
      <input {...input} type={type} />
      { label && <label>{label}</label> }
      <div className={`${styles.errorWrapper} errorWrapper`}>
        <InputError touched={touched} error={error} />
      </div>
    </div>
  </div>
