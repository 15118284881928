import React from 'react'
import { Link } from 'gatsby'
import Newsletter from '../../../containers/_forms/Newsletter'
import styles from './footer.module.scss'
import ScrollMonitoringWrapper from '../../atoms/ScrollMonitoringWrapper'

class Footer extends React.Component {
  constructor () {
    super()

    this.state = {
      animated: false,
      windowHeight : 750
    }
  }

  componentDidMount () {
    this.setState({ windowHeight: window.innerHeight })
  }

  render () {
    const { animated, windowHeight } = this.state

    return (
      <ScrollMonitoringWrapper offsets={{ top: (-windowHeight / 3) }} isInViewportEvent={() => this.setState({ animated: true })}>
        <footer className={`${styles.footer} ${animated && styles.animated}`}>
          <div className={`container ${styles.container}`}>
            <div className={styles.col}>
              <h5>Common</h5>
              <ul>
                <li><Link to="/portfolio">Portfolio</Link></li>
                <li><Link to="/academy">Academy</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </div>
            <div className={styles.col}>
              <h5>Academy</h5>
              <ul>
                <li><Link to="/academy">Dashboard</Link></li>
                <li><Link to="/academy">Profile</Link></li>
                <li><Link to="/academy">Courses</Link></li>
                <li><Link to="/academy">Featured</Link></li>
              </ul>
            </div>
            <div className={styles.col}>
              <h5>Blog</h5>
              <ul>
                <li><Link to="/blog?filtert=featured">Featured</Link></li>
                <li><Link to="/blog?filtert=recent">Most Recent</Link></li>
                <li><Link to="/blog?filtert=popular">Popular</Link></li>
                <li><Link to="/blog">All</Link></li>
              </ul>
            </div>
            <div className={styles.col}>
              <h5><strong>Subscribe</strong> to newsletter</h5>
              <Newsletter />
            </div>
          </div>
        </footer>
      </ScrollMonitoringWrapper>
    )
  }
}

export default Footer
