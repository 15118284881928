import React from 'react'
import styles from './emailButton.module.scss'

const EmailButton = ({ toggled, toggleNav }) =>
  <button className={styles.plane}>
    <i className="fab fa-telegram-plane"></i>
    <i className="fab fa-telegram-plane"></i>
  </button>

export default EmailButton
