import React from 'react'
import { Link } from 'gatsby'
import styles from './siteInfo.module.scss'
import Logo from '../atoms/svg/Logo'
import { StaticQuery, graphql } from "gatsby"

export default ({ open }) =>
<div className={`${styles.siteInfo} ${open && styles.open}`}>
  <Link><Logo />Brady <strong>Edgar</strong></Link>
</div>

    // <StaticQuery
    //     query={
    //       graphql`
    //       {
    //         allWordpressWpMedia (
    //           filter: {
    //             title: {
    //               eq: "logo"
    //             }
    //           }
    //         ) {
    //           edges {
    //             node {
    //               source_url
    //             }
    //           }
    //         }
    //       }
    //       `
    //     }
    //     render={props => (
    //       <div className={`${styles.siteInfo} ${open && styles.open}`}>
    //         <Link><img src={props.allWordpressWpMedia.edges[0].node.source_url} alt="Brady Edgar Logo"/>Brady <strong>Edgar</strong></Link>
    //       </div>
    //     )}
    //   />
