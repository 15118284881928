import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import SigninBox from '../molecules/SignInBox'
import styles from './menu.module.scss'

export default ({ open }) =>
  <StaticQuery
    render={props => (
      <div className={`${styles.headerMenuMain} ${open && styles.open}`}>
          {
            props.allWordpressWpApiMenusMenusItems.edges[0].node.items
            .map(item => <li><Link to={`/${item.object_slug}`} key={item.title}>{ item.title }</Link></li>)
          }
          <li>
            <SigninBox dontShow={!open} />
          </li>

      </div>
    )}
    query={graphql`
      {
        allWordpressWpApiMenusMenusItems(filter: {
        	name: {
            eq: "Main Menu"
          }
        }) {
      		edges {
            node {
              name
              items {
                title
                object_slug
              }
            }
          }
        }
      }
    `}
  />
