// Types
export const actionTypes = ({
  EXAMPLE_DISPATCH: 'EXAMPLE_DISPATCH',
  UPDATE_APP_LOADED_VALUE: 'UPDATE_APP_LOADED_VALUE',
})

// Methods
export const updateStoreExample = value => {
  return (dispatch, getState) => {
    const exampleState = getState().example_value
    return dispatch({ type: actionTypes.EXAMPLE_DISPATCH, value: value })
  }
}

export const updateAppLoadedValue = value => {
  return (dispatch, getState) => {
    const exampleState = getState().example_value
    return dispatch({ type: actionTypes.UPDATE_APP_LOADED_VALUE, value: value })
  }
}
