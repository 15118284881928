import React, { Component } from 'react'
import styles from './signInBox.module.scss'

class SigninBox extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false
    }
  }

  render () {
    return (
      <div className={`${styles.signupBox} ${this.state.open && !this.props.dontShow && styles.showModal} ${this.state.open && this.props.dontShow && styles.showModalDesktopVersion}`}>
        <button className={styles.signinWrapper} onClick={() => this.setState({ open: !this.state.open })}>
          <i class="far fa-user-circle"></i>
          Sign in
        </button>
        <div className={styles.modalSignup}>
          <h5>Welcome</h5>
          <p>Do you want to sign in to the client portal?</p>
          <a className="btn" href="https://www.bradyedgar.io/login" target="_blank" rel="noopener noreferrer">Yes, sign in</a>
        </div>
      </div>
    )
  }
}

export default SigninBox
