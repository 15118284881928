import React from 'react'
import styles from './veggieBurger.module.scss'

const VeggieBurger = ({ toggled, toggleNav }) =>
  <button className={`${styles.veggieBurger} ${toggled && styles.toggled}`} onClick={toggleNav}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>

export default VeggieBurger
