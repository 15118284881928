import React from "react"
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import Menu from '../Menu'
import { throttle } from '../../lib/helpers'
import SiteInfo from '../SiteInfo'
import VeggieBurger from '../atoms/VeggieBurger'
import styles from "./header.module.scss"

class Header extends React.Component {
  constructor () {
    super()

    this.state = {
      animated: false,
      open: false,
      sticky: false,
      stickyAnimated: false
    }
  }

  componentDidMount () {
    this.trackHeader()
    setTimeout(() => this.setState({ animated: true }), 1000)
  }

  trackHeader = () => {
    const detectPosition = () => {
      const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
      const el = document.getElementById('header-id')

      if (!el) {
        return false
      }
      
      this.setState({ sticky: scrollTop > el.clientHeight, stickyAnimated: scrollTop > el.clientHeight })
    }

    window.addEventListener('scroll', throttle(detectPosition), 500)
  }

  render () {
    const { animated, open, sticky, stickyAnimated } = this.state
    const { siteTitle, appLoaded } = this.props

    return (
      <header id="header-id" className={`${styles.header} ${appLoaded && `${styles.animated}`} ${sticky && styles.sticky} ${stickyAnimated && styles.stickyAnimated}`}>
        <div className="container">
          <SiteInfo open={open} />
          <Menu open={open} />
          <VeggieBurger toggleNav={() => this.setState({ open: !open })} toggled={open} />
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

function mapStateToProps({ mainReducer: { appLoaded } }) {
  return { appLoaded }
}

export default connect(mapStateToProps)(Header)
