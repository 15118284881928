import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { reducer as formReducer } from 'redux-form'
import thunkMiddleware from 'redux-thunk'
import exampleInitialState from './initialState'
import { mainReducer } from './reducers'

// REDUCERS
const rootReducer = combineReducers({
  mainReducer,
  form: formReducer,
})

export function initializeStore (initialState = exampleInitialState) {
  return createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware)))
}
