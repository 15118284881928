import React from 'react'
import Button from '../../atoms/EmailButton'
import styles from './newsletter.module.scss'
import { Field, reduxForm } from 'redux-form'
import Input from '../../atoms/_forms/_inputs/Input'
import TextArea from '../../atoms/_forms/_inputs/TextArea'
import validate from './validate'

class Newsletter extends React.Component {
  render () {
    const { handleSubmit, submitting, errored, fields = {}, submitFailed, fieldErrors = {}, submissionError, submissionErrorMsg } = this.props

    return (
      <div className={styles.newsletterForm}>
      {
        submissionError === false
          ? <div className={styles.message}>
              <h3>Thank you for signing up!</h3>
              <p>You will be the first to know about any recent news or tutorials I have offer.  You will also recieve exsclusive deals and promos</p>
            </div>
          : <form onSubmit={handleSubmit}>
              {
                !errored
                ? <>
                  <Field
                    name="email"
                    type="email"
                    component={Input}
                    label="Subscribe today"
                  />
                  <div className="fields inline-elements bottom">
                    {
                      submissionError &&
                      <div className="fields inline-elements error">
                        <p>{submissionErrorMsg}</p>
                      </div>
                    }
                    <Button submit={true} text="Send message" disabled={submitting} />
                  </div>
                </>
                : <div className={styles.message}>
                  <h3>Oh no, It looks like something went wrong!</h3>
                  <p>Do don't worry though, we have archived your email attempt and will get back to you as soon as possible. Please feel free to try again later too.</p>
                  </div>
                }
              </form>

        }
        <ul>
          <li><a href="https://www.youtube.com/channel/UCMqRBS56afOHdhdlWwNbhFg" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a></li>
          <li><a href="https://dribbble.com/BradyEdgar" target="_blank" rel="noopener noreferrer"><i className="fab fa-dribbble"></i></a></li>
          <li><a href="https://twitter.com/BradyEdgar1" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
        </ul>
      </div>
    )
  }
}

export default reduxForm({
  form: 'newsletter',
  validate,
})(Newsletter)
