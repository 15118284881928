import 'isomorphic-fetch'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import Form from '../../components/molecules/Newsletter'


class Newsletter extends Component {
  constructor () {
    super()

    this.state = {
      error: false,
      submissionError:  null,
      submissionErrorMsg: '',
    }
    this.formRef = null
  }

  submit = ({ email }) => {
    const url = window.location.href.replace('https://', '').replace('/contact', '').replace(/\/$/, "")

    let apiBase = 'https://wp.bradyedgar.io'
    switch (url) {
      case 'localhost:8000':
          apiBase = 'https://api.bradyedgar.local'
        break;
      case 'staging.bradyedgar.io':
          apiBase = 'https://wp.bradyedgar.io'
        break;
      case 'www.bradyedgar.io':
          apiBase = 'https://wp.bradyedgar.io'
        break;
    }

    return fetch(`${apiBase}/wp-json/wp/v2/newsletter-archiver/add-entry`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({ email })
    })
      .then(({ status }) => {
        this.setState({
          submissionError: status !== 200,
          error: status !== 200
        })
    	})
  }

  render () {
    const { error, submissionError, submissionErrorMsg } = this.state

    return (
      <Form
        ref={ref => this.formRef = ref}
        onSubmit={this.submit}
        errored={error}
        submissionError={submissionError}
        submissionErrorMsg={submissionErrorMsg}
      />
    )
  }
}

export default connect()(Newsletter)
