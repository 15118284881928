import React from 'react'
import InputError from '../InputError'
import styles from './textarea.module.scss'

export default ({ input, label, type, meta: { touched, error } }) =>
  <div className={`${styles.textarea} ${touched && error ? styles.invalid : ''}  ${touched && !error && input.value ? styles.valid : ''}`}>
    <div>
      <textarea {...input} data-gramm_editor="false"></textarea>
      { label && <label>{label}</label> }
      <div className={styles.errorWrapper}>
        <InputError touched={touched} error={error} />
      </div>
    </div>
  </div>
