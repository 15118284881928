const validateEmail = value => {
  let error

  if (!value) {
    error = 'This is a required field'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address'
  }

  if (error) {
    return error
  }
}

export default values => {
  const errors = {}

  if (!values.email) {
    errors.email = 'This is a required field'
  } else if (validateEmail(values.email)) {
    errors.email = 'Invalid email address'
  }
  return errors
}
