import { actionTypes } from './actions'
import exampleInitialState from './initialState'

export const mainReducer = (state = exampleInitialState, action) => {
  switch (action.type) {
    case actionTypes.EXAMPLE_DISPATCH:
      return Object.assign({}, state, {
        example_value: action.value,
      })
    case actionTypes.UPDATE_APP_LOADED_VALUE:
      return Object.assign({}, state, {
        appLoaded: action.value,
      })
    default: return state
  }
}
