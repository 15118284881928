import React from 'react'
import IntersectionVisible from 'react-intersection-visible'

class Wrapper extends React.Component {
    onHide = entries => {
      if (this.props.onHide) {
        this.props.onHide()
      }
    }

    onShow = entries => {
      if (this.props.onShow) {
        this.props.onShow()
      }
    }

    onIntersect = entries => {
      if (this.props.isInViewportEvent && this.props.threshold && entries[0].intersectionRatio > this.props.threshold[0]) {
        this.props.isInViewportEvent(true)
      }

      if (this.props.isInViewportEvent && entries[0].intersectionRatio > 0.5) {
        this.props.isInViewportEvent(true)
      }

      if (this.props.isInViewportEvent && entries[0].intersectionRatio > 1) {
        this.props.isFullyInViewportEvent(true)
      }
    }

  render () {
    return (
      <IntersectionVisible
        onIntersect={this.onIntersect}
        onHide={this.onHide}
        onShow={this.onShow}
        options={{ threshold: this.props.threshold ? this.props.threshold : [0.5, 1] }}
      >
        { this.props.children }
      </IntersectionVisible>
    )
  }
}

export default Wrapper
