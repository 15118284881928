import React from 'react'

export default () =>
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 205.2 205.2" style={{ enabldBackground: 'new 0 0 205.2 205.2' }}>
    <g>
      <path className="st0" style={{ fill: '#ee0824' }} d="M195.8,90.5c-5.9-46.2-45.4-81.9-93.2-81.9c-8,0-15.7,1-23.1,2.9c-8.6,2.2-16.7,5.6-24.2,9.9v81.2c0,0,0,0,0,0
    c0,26.1,21.2,47.3,47.3,47.3c21.9,0,40.4-15,45.7-35.2h23c-2.5,14.4-9.4,27.3-19.4,37.3c-12.7,12.7-30.1,20.5-49.4,20.5
    c-19.3,0-36.7-7.8-49.4-20.5c-12.7-12.7-20.5-30.1-20.5-49.4V39.7c-15,16.7-24.2,38.7-24.2,62.9c0,51.9,42.1,94,94,94
    c47.8,0,87.3-35.7,93.2-81.9c0.5-4,0.8-8,0.8-12.1C196.6,98.5,196.3,94.4,195.8,90.5z M125.9,114.1H82.2v-23h43.8V114.1z
    M148.3,90.5c-5.4-20.3-23.8-35.2-45.7-35.2c-8.4,0-16.3,2.2-23.1,6.1V36.7c7.2-2.5,15-3.9,23.1-3.9c19.3,0,36.7,7.8,49.4,20.5
    c10,10,16.9,22.9,19.4,37.3H148.3z"/>
    </g>
  </svg>
